body {
    margin: 0;
}

@font-face {
    font-family: Noto Sans;
    src: url("../../public/NotoSans.ttf");
    font-style: normal;
    font-weight: 1 1000;
}

:root {
    --width-desktop: 360px;
    --width-mobile: 343px;
    --white: #FFFFFF;
    --backgroundColor-light-one: #FFFFFF;
    --backgroundColor-light-second: #F6F6F8;
    --backgroundColor-light-third: #EBECEE;
    --backgroundColor-dark-one: #141414;
    --backgroundColor-dark-second: #1F1F1F;
    --backgroundColor-dark-third: #303030;
    --backgroundColor-ripple: #f2f2f2;
    --backgroundColor-grey: #b0b0b0;
    --backgroundColor-button-registration: #1976d2;
    --backgroundColor-error: #FDEDEB;
    --gradient-color-start: #0161A4;
    --text-color-link: #00BEB4;
    --text-color-error: #E74C3C;
    --text-color-more: #D02B21;
    --text-color-medium: #E7B10A;
    --text-color-pending: #F39C12;
    --text-color-dark: #000000;
    --text-color-available: #06205E;
    --text-color-resend: #0159A2;
    --text-light-one: #181A21;
    --text-dark-one: #FEFEFE;
    --text-color-grey: #848790;
    --text-color-grey-second: #9C9C9D;
    --text-light-grey-third: #849AA8;
}

:root {
    --font-family: "Noto Sans", sans-serif;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: auto;
}

a {
    color: var(--text-color-link) !important;
}

.wrapper {
    text-align: center;
}

.vector-position {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: -75px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    color: var(--tg-theme-text-color) !important;
    font-family: Noto Sans, serif !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-bottom: 2px !important;
    -webkit-text-fill-color: var(--tg-theme-text-color) !important;
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.logoHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 36px;
    margin-left: 23.7px;
}

.paramField {
    display: flex;
    width: calc(100% - 24px);
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 12px;
    margin-left: 24px;
}

.paramKey {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    color: var(--text-color-grey);
    text-align: left;
}

.paramValue {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0 24px 0 0;

    word-break: break-all;

    color: var(--text-light-one);
    text-align: right;
}

.withdrawSummaryText {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 24px 0 0 0;
    color: var(--text-color-grey);
}

@media (width <=480px) {
    .component {
        margin-top: 80px;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .confirmContainer {
        margin-top: 24px;
        padding-bottom: 24px;
        border-radius: 12px;
        width: var(--width-mobile);
        background-color: var(--backgroundColor-light-second);
    }

    .confirmWithdrawContainer {
        margin-top: 24px;
        width: var(--width-mobile);
        border-radius: 12px;
        background-color: var(--backgroundColor-light-second);
        padding-bottom: 24px;
    }

    .innerComponent {
        width: var(--width-mobile);
    }

    .registrationButton {
        width: var(--width-mobile);
    }

    .registrationButtonContent {
        width: var(--width-mobile);
    }

    .countryCodeSelect {
        width: 120px;
    }

    .modalComponent {
        width: var(--width-mobile);
        height: 433px;
    }

    .modalOtpComponent {
        width: calc(var(--width-mobile) - 46px);
    }
}

.confirmContainer {
    height: 124px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.confirmContainerHeader {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    margin: 24px 0 0 24px;
    color: var(--text-light-one);
    text-align: left;
}

.confirmContainerMain {

    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    margin: 12px 24px 0 24px;
    color: var(--text-color-grey);
    text-align: left;
}

.confirmButton {
    height: 56px;
    width: 48%;
    margin-top: 24px;
}

.confirmButtonContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.confirmWithdrawContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (width > 480px) {
    .component {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .confirmContainer {
        margin-top: 24px;
        width: var(--width-desktop);
        height: 124px;
        border-radius: 12px;
        padding-bottom: 24px;
        background-color: var(--backgroundColor-light-second);
    }

    .confirmWithdrawContainer {
        margin-top: 24px;
        width: var(--width-desktop);
        border-radius: 12px;
        background-color: var(--backgroundColor-light-second);
        padding-bottom: 24px;
    }

    .innerComponent {
        width: var(--width-desktop);
    }

    .registrationButton {
        width: var(--width-desktop);
    }

    .registrationButtonContent {
        width: var(--width-desktop);
    }

    .countryCodeSelect {
        width: 140px;
    }

    .modalComponent {
        width: var(--width-desktop);
        height: 319px;
    }

    .modalOtpComponent {
        width: calc(var(--width-desktop) - 46px);
    }
}

.welcomeText {
    margin: 0 0 16px 0;
    color: var(--text-light-one);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
    font-family: var(--font-family);
}

.maintainHeaderText {
    margin: 0 0 16px 0;
    color: var(--text-light-one);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
    font-family: var(--font-family);
}

.maintainHeaderBody {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: var(--text-color-grey);
}

.secondaryText {
    margin: 0 0 32px 0;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: var(--text-color-grey);
}

.registrationButton {
    position: relative;
    display: flex;
    margin-bottom: 16px;
    border-radius: 12px;
    border: none;
    padding: 8px 0px;
    height: 72px;
    box-shadow: 0 0 24px 0 rgba(24, 26, 33, 0.04), 0 60px 120px 0 rgba(24, 26, 33, 0.08);
    background: var(--backgroundColor-light-one);
    justify-content: center;
    flex-direction: row;
    align-items: center;
    transition: 300ms;
}

.registrationButton:hover {
    background: var(--backgroundColor-light-third);
    opacity: 0.90;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}

.registrationButton:active {
    background: var(--backgroundColor-light-second);
    opacity: 0.8;
}

.registrationButtonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    height: 56px;
}

.registrationButtonContentIconText {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.registrationButtonImageContainer {
    width: 24px;
    height: 24px;
}

.registrationButtonText {
    margin: 0 0 0 24px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-light-one);
}

.backButton {
    height: 40px;
    margin-bottom: 16px;
}

.statementIcon {
    width: 24px;
    height: 24px;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.firstCheckBox {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.secondCheckBox {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.thirdCheckBox {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.checkBox-h1 {
    text-align: left;
    margin: 0 0 0 10px;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.startButton {
    margin-top: 24px;
    height: 56px;
}

.finishButton {
    margin-top: 48px;
    height: 56px;
}

.registrationInput {
    margin-top: 32px;
}

.pin_input {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex: 1;
}

.pin_input__field {
    width: 51px;
    height: 57px;
    background: var(--backgroundColor-light-second);
    border-radius: 12px;
    border: 1px solid var(--backgroundColor-light-second);
    padding: 0;
    font-size: 16px !important;
    text-align: center;
}

.pin_input__field-error {
    background: var(--backgroundColor-error);
    border: 1px solid var(--backgroundColor-error);
}

.pin_input__field:focus-visible {
    outline: 2px solid var(--text-color-link);
}

.confirmEmailText {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.confirmCodeText {
    margin-top: 8px;
    margin-bottom: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.codeInputBlock {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.resendCodeText {
    color: var(--text-color-resend);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 18px;
    margin-bottom: 24px;
    align-self: flex-start;
}

.resendCodeText-inactive {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 18px;
    margin-bottom: 24px;
    align-self: flex-start;
}

.phoneInput {
    width: 100%;
}

.mobileInputBlock {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.countryCodeSelect {
    margin-right: 12px;
}

.countryCodeChev {
    margin: 0;
    width: 22px !important;
    height: 22px !important;
}

.countryCodeSelect-defaultCountry {
    margin: 0;
    background: var(--backgroundColor-grey);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

::marker {
    color: var(--text-color-grey);
}

::-webkit-scrollbar:hover {
    background-color: rgba(255, 255, 255, 0.172549) !important;
}

::-webkit-scrollbar {
    border-radius: 5px !important;
    border: 1px solid transparent !important;
    background-color: rgba(255, 255, 255, 0.101961) !important;
    background-clip: content-box !important;
    width: 10px !important;
}

p {
    margin: 0;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.478431) !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    border: 1px solid transparent !important;
    background-color: rgba(255, 255, 255, 0.32549) !important;
    background-clip: content-box !important;
}

.whaleIcon {
    width: 120px;
    height: 120px;
}

.modalComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--white);
    border-radius: 12px;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(24, 26, 33, 0.08), 0px 0px 24px rgba(24, 26, 33, 0.04);
}

.modalOtpComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--white);
    border-radius: 12px;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(24, 26, 33, 0.08), 0px 0px 24px rgba(24, 26, 33, 0.04);
}

.modalHeader {
    margin: 0 0 19px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.modalHeaderText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    margin: 0;
}

.modalInput {
    margin-bottom: 16px;
    position: sticky;
    z-index: 1;
}

.modalContent {
    overflow: auto;
    height: 100%;
}

.modalContent-empty {
    height: 100%;
}

.modalEmptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modalEmptyHeader {
    color: var(--text-light-one);
    margin: 8px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.modalEmptyBottom {
    color: var(--text-color-grey);
    margin: 8px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.modalItem {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalItemLogo {
    display: flex;
    flex-direction: row;
}

.modalItemLogoTextWithoutMargin {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 0;
}

.modalItemLogoText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 24px;
}

.modalItemCode {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.modalItemImg {
    width: 36px;
    height: 36px;
}

.countryCodeSelect-defaultCountry {
    margin: 0;
    background: var(--backgroundColor-grey);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.selectImg {
    width: 24px;
    height: 24px;
}

.nameAndPassword {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.nameInput {
    margin-top: 24px;
}

.passwordInput {
    margin-top: 24px;
}

.createPasswordHelp {
    text-align: left;
    margin-top: 4px;
    margin-bottom: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.referralCodeText {
    margin-top: 24px;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.MuiFormLabel-asterisk {
    display: none;
}

.errorText {
    color: var(--text-color-error);
    margin: 4px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.registrationEmailError {
    color: var(--text-color-error);
    margin: 4px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.forgotPasswordText {
    text-align: left;
    color: var(--text-color-link);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 18px 0 0 0;
}

.loginButtonError {
    margin-top: 42px;
}

.loginButton {
    margin-top: 62px;
}

.registerNowButton {
    margin-top: 32px;
}

.loginErrorText {
    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 18px 0 0 0;
}

.passwordRecoveryText {
    margin: 0;
    text-align: left;
    color: var(--text-light-one);
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.forgotPasswordContainerText {
    margin-top: 24px;
    padding: 16px 24px;
    display: flex;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
}

.background {
    background: linear-gradient(90deg, var(--gradient-color-start) 0%, var(--text-color-link) 100%);
    height: 150px;
    width: 100%;
    position: absolute;
}

.logo {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 24px;
    position: absolute;
    display: flex;
    flex-direction: row;
}

.tails {
    margin-left: calc(100% - 110px);
    top: -10px;
    position: absolute;
}

.divider {
    border-color: var(--backgroundColor-light-second) !important;
    margin-top: 24px;
}

.setupMfCode-item-description {
    margin-bottom: 140px;
    margin-left: 28px;
    margin-right: 20px;
}

.setupMfCode-item-description-text {
    text-align: left;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.setupMfCode-item-secretKey {
    display: flex;
    margin-top: 24px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
}

.qrCode-image {
    margin-top: 24px;
}

.setupMfCode-item-secretKey-inner {
    width: 100%;
}

.copyButton {
    width: 24px;
    height: 24px;
}

.key {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.key {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.key-text-inner {
    display: flex;
    margin-top: 4px;
    justify-content: space-between;
    flex-direction: row;
}

.key-text {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.componentBorderless {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    height: 100%;
    width: 100%;
}

.verificationLogoText {
    margin: 16px 0 0 0;
    text-align: left;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationLastName {
    margin-top: 24px;
}

.verificationCheckBoxGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.verificationCheckBox {
    align-self: flex-start;
}

.innerVerificationCheckBox {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.innerVerificationCheckBoxText {
    text-align: left;
    margin: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationSumSumText {
    text-align: center;
    margin: 209px 0 0 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationSumSubButton {
    margin-top: 24px;
}

.verificationSumSumPoweredText {
    margin-top: 24px;
    text-align: center;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationWhale {
    margin: 16px 0 0 0;
}

.verificationTestStartLogo {
    margin-top: 376px;
    margin-bottom: 0;
    text-align: center;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationTestDescription {
    text-align: center;
    margin-bottom: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationStartTestButton {
    margin-top: 188px;
}

.verificationQuestionNumber {
    text-align: left;
    color: var(--text-light-one);
    margin-bottom: 0;
    margin-top: 16px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationQuestion {
    text-align: center;
    color: var(--text-light-one);
    margin-bottom: 0;
    margin-top: 16px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.questionText {
    margin-bottom: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 24px;
}

.answersDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.answerDiv {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.answerCheckBox {
    margin-right: 16px;
}

.answerText {
    text-align: left;
    margin: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.redirectHeaderText {
    font-family: Noto Sans, serif;
    font-style: normal;
    margin-bottom: 0;
    text-align: center;
}

.otpSelect {
    width: 100%;
    height: 60px;
    margin-top: 24px;
}

.modalOtpItemCode {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.emailRecoveryInput {
    margin-top: 12px;
}